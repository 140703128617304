import React from 'react';

class Experience extends React.Component {
    render() {
        return (
            <section className="experience-wrapper wrapper">
                <div className="container-fluid">
                    <div className="wrapper-title">
                        <span className="heading-meta">سابقه کار</span>
                        <h2>سوابق کاری</h2>
                    </div>

                    <div className="row">
                        <ul className="timeline">
                            <li>
                                <div className="timeline-content">
                                    <span className="date">1400 ...</span>
                                    <h5>تاسیس آژانس دیجیتال تاپینو</h5>
                                    <h4>Founder of Topino Agency</h4>
                                    <p>اسفند ماه ۱۳۹۹ تیمی رو تحت عنوان تاپینو تشکیل دادم که در زمینه طراحی سایت، امور گرافیکی و سئو فعالیت میکنیم</p>
                                </div>
                            </li>
                            <li>
                                <div className="timeline-content">
                                    <span className="date">مهر تا بهمن 1399</span>
                                    <h5>مکس بکس | ارائه دهنده خدمات و آموزش در زمینه بلاک‌چین</h5>
                                    <h4>Vuejs Developer, UI/UX Designer</h4>
                                    <p>در این شرکت به عنوان یک توسعه دهنده طراح UI/UX مشغول بودم</p>
                                </div>
                            </li>
                            <li>
                                <div className="timeline-content">
                                    <span className="date">اردیبهشت تا مهر 1399</span>
                                    <h5>شرکت فنی مهندسی زبدگان</h5>
                                    <h4>Front-end Developer, SEO Expert</h4>
                                    <p>مسئول کلیه امروز مربوط به Front شامل Html, Css, JavaScript, React, React Native و سئو و بهینه سازی</p>
                                </div>
                            </li>
                            <li>
                                <div className="timeline-content">
                                    <span className="date">1398 تا 1399</span>
                                    <h5>شرکت بازرگانی شیمیایی دانشمند</h5>
                                    <h4>توسعه دهنده سایت و کارشناس سئو</h4>
                                    <p>در این شرکت تحت عنوان توسعه دهنده، مدیریت سایت های این مجموعه و کارشناس سئو مشغول بودم</p>
                                </div>
                            </li>
                            <li>
                                <div className="timeline-content">
                                    <span className="date">1396 تا 1398</span>
                                    <h5>شرکت توسعه تبلیغات روبیک</h5>
                                    <h4>مدیریت بخش وب سایت، توسعه دهنده</h4>
                                    <p>توی شرکت روبیک نزدیک به 2 سال توسعه دهنده بودم و تمامی پیگیری ها و ارتباط با مشتریان به عهده بنده بود</p>
                                </div>
                            </li>
                            <li>
                                <div className="timeline-content">
                                    <span className="date">1394 تا 1395</span>
                                    <h5>شرکت تفکر خلاق آرتنوس</h5>
                                    <h4>توسعه دهنده html و css</h4>
                                    <p>در واقع آرتنوس اولین شرکتی بود که من اونجا مشغول به کار شدم که توسعه دهنده Front وبسایت بودم</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        )
    }
}

export default Experience;