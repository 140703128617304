import React from "react"

import SEO from "../components/Seo"
import Layout from "../components/Layout"

import About from "../components/resume/About"
import Experience from "../components/resume/Experience"
import Skills from "../components/home/Skills"

import Image1 from '../assets/images/resume-old.jpg';

const ResumePage = () => {
  return (
    <Layout>
      <SEO
        title="رزومه و سابقه کار"
        description="اگر بخوام رزومه یا استارت خودم رو بگم داستان از اونجا شروع میشه که سال 93 یه وبلاگ داشتم که توش محتوا میزاشتم... یه روز با خودم گفتم که چقدر خوب میشد"
      />

      <About image={Image1} />
      <Experience />
      <Skills />
    </Layout>
  )
}

export default ResumePage