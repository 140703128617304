import React from 'react';
import { Link } from 'gatsby';

class Skills extends React.Component {
    render() {
        return (
            <section className="skills-wrapper wrapper">
                <div className="container-fluid">
                    <div className="wrapper-title">
                        <span className="heading-meta">مهارت ها</span>
                        <h2>تخصص های من</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-7">
                            <p>من تقریبا نزدیک به چند سال فقط html و css کار میکردم که واقعا تونستم توی این دو تا حرفه به نقطه ی خوبی برسم و توش پیشرفت کنم. شرکت هایی هم که توش مشغول بودم خیلی تونستن به دانش من اضافه کنن.</p>
                            <p>که از ابتدای سال 98 تصمیم گرفتم خودم رو آپدیت کنم و فقط دانشم html و css و jquery نباشه. که استارت یادگیری جاوا اسکریپت و فریم ورک های اون رو زدم و اون رو تونستم یاد بگیرم.</p>
                            <p>در حال حاضر تمرکز اصلیم روی پیشرفت توی React و سئو هست.</p>
                            <p>دوره <Link to="/blog">آموزش طراحی سایت رایگان</Link> هم اکنون در دسترس می باشد.</p>
                        </div>
                        <div className="col-lg-5">
                            <h3 className="bar-title">
                                HTML5 
                            <span className="percent"> 95%</span>
                            </h3>
                            <div className="bar">
                                <div className="bar-width" style={{ width: '95%' }}></div>
                            </div>
                            <h3 className="bar-title">
                                CSS3 
                            <span className="percent"> 90%</span>
                            </h3>
                            <div className="bar">
                                <div className="bar-width" style={{ width: '90%' }}></div>
                            </div>
                            <h3 className="bar-title">
                                JavaScript, Jquery 
                            <span className="percent"> 70%</span>
                            </h3>
                            <div className="bar">
                                <div className="bar-width" style={{ width: '70%' }}></div>
                            </div>
                            <h3 className="bar-title">
                                SEO 
                            <span className="percent"> 80%</span>
                            </h3>
                            <div className="bar">
                                <div className="bar-width" style={{ width: '80%' }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Skills;